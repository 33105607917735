/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import Rollbar from "common/utils/Rollbar"

Rollbar.init()

// Import all the third party stuff
import React from "react"
import "sanitize.css/sanitize.css"
// eslint-disable-next-line
import formActionSaga from "common/pkg/redux-form-saga"
import confirmDialog from "corporate/utils/confirmDialog"
import requestValue from "corporate/utils/requestValue"

// Import root app
import App from "corporate/containers/App"

// Import CSS reset and Global Styles
import theme, { muiTheme, jss } from "./theme"
import configureStore from "./configureStore"
import ConnectedApp from "common/ConnectedApp"

export default function CorporateApp() {
  return (
    <ConnectedApp
      authTokenName={"X-CorporateUser-Token"}
      authEmailName={"X-CorporateUser-Email"}
      configureStore={configureStore}
      jss={jss}
      theme={theme}
      muiTheme={muiTheme}
      confirmDialog={confirmDialog}
      requestValue={requestValue}
    >
      <App />
    </ConnectedApp>
  )
}
